import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Layout from "../components/global/Layout";
import SEO from "../components/global/SEO";

const fourohfourPage = () => {

  const data = useStaticQuery(graphql`
  {
    fourohfourpage: wordpressPage(title: { eq: "404" }) {
      title
      id
      content
      yoast {
        focuskw
        title
        metadesc
        opengraph_title
        opengraph_description
        opengraph_updated_time
        twitter_title
        twitter_description
        linkdex
        metakeywords
        meta_robots_noindex
        meta_robots_nofollow
        meta_robots_adv
        canonical
        redirect
        opengraph_url
        opengraph_site_name
        opengraph_type
      }
    }
  }
`);

  return (
    <Layout>
      <SEO yoast={data.fourohfourpage.yoast} />
      <div className="l-content l-content--fixed l-vspace t-copy" dangerouslySetInnerHTML={{ __html: data.fourohfourpage.content }}></div>
    </Layout>
  );
};

export default fourohfourPage;